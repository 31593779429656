// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type {
  PrismicStructuredTextType,
  PrismicLinkType,
  PrismicImageType,
} from '~schema';

export type ClassKey =
  | 'root'
  | 'image'
  | 'content'
  | 'price'
  | 'title'
  | 'subtitle'
  | 'description'
  | 'link';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  item: {
    item_price?: ?PrismicStructuredTextType,
    item_title?: ?PrismicStructuredTextType,
    item_subtitle?: ?PrismicStructuredTextType,
    item_description?: ?PrismicStructuredTextType,
    item_link_label?: ?PrismicStructuredTextType,
    item_link?: ?PrismicLinkType,
    item_image?: ?PrismicImageType,
  },
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: 350,
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  price: {},
  title: {
    '& > *': {
      textTransform: 'uppercase',
      fontFamily: theme.typography.h1.fontFamily,
    },
  },
  subtitle: {
    color: theme.palette.grey[600],
  },
  description: {
    paddingTop: theme.spacing(3),
    '& > ul': {
      textAlign: 'left',
    },
  },
  link: {
    marginTop: theme.spacing(3),
  },
});

const RatesSliceItem = ({
  item,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <Paper className={classnames(className, classes.root)} {...props}>
      {item.item_image ? (
        <NodeImage data={item.item_image} className={classes.image} />
      ) : null}
      <div className={classes.content}>
        {item?.item_price || item?.item_title ? (
          <div>
            <RichText {...item.item_price} className={classes.price} />
            <RichText {...item.item_title} className={classes.title} />
            {item?.item_subtitle ? (
              <RichText {...item.item_subtitle} className={classes.subtitle} />
            ) : null}
          </div>
        ) : null}
        {item?.item_description ? (
          <RichText
            {...item?.item_description}
            className={classes.description}
          />
        ) : null}
        {item?.item_link ? (
          <Button
            component={FieldLink}
            field={item?.item_link}
            disableTouchRipple
            variant="contained"
            color="secondary"
            aria-label={item?.item_link_label?.text}
            className={classes.link}
          >
            {item?.item_link_label?.text}
          </Button>
        ) : null}
      </div>
    </Paper>
  );
};

RatesSliceItem.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(RatesSliceItem);
