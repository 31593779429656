// @flow
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import newDate from '~helpers/newDate';
import Join from '~components/Join';

import type { Props } from './types';
import styles from './styles';
import messages from './messages';

const RearrangeableBlockSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const intl = useIntl();

  const hourOptions = { hour: 'numeric', minute: 'numeric' };
  const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const eventDate = newDate(data?.primary?.slice_date_picker);

  const dateElements = [
    Number.isNaN(eventDate.getTime()) ? null : (
      <Typography key="eventDate" variant="h6" className={classes.time}>
        {intl.formatDate(eventDate, dateOptions)}
      </Typography>
    ),
    Number.isNaN(eventDate.getTime()) ? null : (
      <Typography key="eventHours" variant="h6" className={classes.hours}>
        <FormattedMessage {...messages.meetTime} />
        {intl.formatDate(eventDate, hourOptions)}
      </Typography>
    ),
  ];

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid
        container
        alignItems="center"
        spacing={6}
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} sm={6} className={classes.itemPrimaryWrapper}>
          <div className={classes.titleWrapper}>
            {data.primary?.slice_title ? (
              <RichText
                {...data.primary?.slice_title}
                className={classes.title}
              />
            ) : null}
            {dateElements?.length ? (
              <div className={classes.date}>
                <Join separator={<Divider orientation="vertical" flexItem />}>
                  {dateElements}
                </Join>
              </div>
            ) : null}
          </div>
          {data.primary?.slice_description ? (
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
          ) : null}
          {data?.primary?.slice_link ? (
            <Button
              component={FieldLink}
              field={data.primary?.slice_link}
              disableTouchRipple
              variant="contained"
              color="secondary"
              aria-label={data?.primary?.slice_link_label?.text}
              className={classes.link}
            >
              {data?.primary?.slice_link_label?.text}
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.itemSecondaryWrapper}>
          <NodeImage
            data={data.primary?.slice_image}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

RearrangeableBlockSlice.defaultProps = {
  className: undefined,
};

export const StyledRearrangeableBlockSlice = withStyles<*, *, Props>(styles)(
  RearrangeableBlockSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledRearrangeableBlockSlice>,
>()(StyledRearrangeableBlockSlice);
