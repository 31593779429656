// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemPrimaryWrapper: {},
  itemSecondaryWrapper: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px !important',
    },
  },
  titleWrapper: {
    marginBottom: theme.spacing(2),
  },
  title: {
    '& > *': {
      color: theme.palette.text.secondary,
    },
  },
  description: {
    '& *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    '& a, & strong': {
      color: theme.palette.secondary.main,
      fontFamily: theme.typography.h1.fontFamily,
    },
  },
  link: {
    marginTop: theme.spacing(3),
  },
  image: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 300,
    },
  },
  date: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    '& hr': {
      margin: theme.spacing(0, 1.5),
      backgroundColor: 'currentColor',
    },
  },
  time: {},
  hours: {
    fontFamily: theme.typography.h1.fontFamily,
  },
});

export default styles;
