// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import map from 'lodash/map';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import TitleDivider from '~components/TitleDivider';

import type { Props } from './types';
import styles from './styles';

const FaqsSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <TitleDivider
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        classes={{ root: classes.titleWrapper }}
      />
      <div>
        {map(data?.items, (item, index: number) =>
          item && item?.item_question && item?.item_answer ? (
            <ExpansionPanel
              key={index}
              className={classes.expansionPanel}
              elevation={0}
              square={false}
            >
              <ExpansionPanelSummary
                expandIcon={<ChevronRightIcon />}
                aria-controls={`panel-${index}-${data.id}-content`}
                id={`panel-${index}-${data.id}-header`}
              >
                <RichText
                  {...item.item_question}
                  className={classes.question}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <RichText {...item.item_answer} className={classes.answer} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null,
        )}
      </div>
    </Component>
  );
};

FaqsSlice.defaultProps = {
  className: undefined,
};

export const StyledFaqsSlice = withStyles<*, *, Props>(styles)(FaqsSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledFaqsSlice>,
>()(StyledFaqsSlice);
