// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {
    '& .keen-slider': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      clipPath: 'inset(-100vw -100vw -100vw 0)',
    },
  },
  itemsGridItemPrimary: {},
  itemsGridItemSecondary: {},
  title: {},
  description: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  navigation: {
    position: 'absolute',
    left: '50%',
    bottom: theme.spacing(-2),
    transform: 'translateX(-50%)',
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  navigationDot: {
    width: 8,
    height: 8,
    border: '1px solid',
    borderRadius: '50%',
    borderColor: ({ data }) =>
      data?.primary?.slice_background_color != null
        ? theme.palette.grey[200]
        : theme.palette.grey[600],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    '&.active': {
      background: ({ data }) =>
        data?.primary?.slice_background_color != null
          ? theme.palette.grey[200]
          : theme.palette.grey[600],
    },
  },
});

export default styles;
