// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'store';

export default {
  location: ({
    id: `${prefix}.location`,
    defaultMessage: 'LOCALIZACIÓN',
    description: 'Store location label',
  }: MessageDescriptor),
  hours: ({
    id: `${prefix}.hours`,
    defaultMessage: 'WORKING HOURS',
    description: 'Store hours label',
  }: MessageDescriptor),
};
