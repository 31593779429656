// @flow
import * as React from 'react';

import HeaderSlice from '~components/slices/HeaderSlice';
import PlainTextSlice from '~components/slices/PlainTextSlice';
import ContentGridSlice from '~components/slices/ContentGridSlice';
import MediaGridSlice from '~components/slices/MediaGridSlice';
import QuoteSlice from '~components/slices/QuoteSlice';
import ParallaxBannerSlice from '~components/slices/ParallaxBannerSlice';
import HighlightsSlice from '~components/slices/HighlightsSlice';
import FaqsSlice from '~components/slices/FaqsSlice';
import VideoSlice from '~components/slices/VideoSlice';
import SingleImageSlice from '~components/slices/SingleImageSlice';
import RearrangeableBlockSlice from '~components/slices/RearrangeableBlockSlice';
import EventSlice from '~components/slices/EventSlice';
import CardsSliderSlice from '~components/slices/CardsSliderSlice';
import RatesSlice from '~components/slices/RatesSlice';
import LocationMapSlice from '~components/slices/LocationMapSlice';
import FullwidthCarouselSlice from '~components/slices/FullwidthCarouselSlice';
import ColumnTextSlice from '~components/slices/ColumnTextSlice';
import EmbeddedContentSlice from '~components/slices/EmbeddedContentSlice';
import CallToActionSlice from '~components/slices/CallToActionSlice';
import StoriesSlice from '~components/slices/StoriesSlice';

export default function useAllNodeBodyComponents() {
  const nodeBodyComponents = {
    header: HeaderSlice,
    plain_text: PlainTextSlice,
    content_grid: ContentGridSlice,
    media_grid: MediaGridSlice,
    quote: QuoteSlice,
    parallax_banner: ParallaxBannerSlice,
    highlights: HighlightsSlice,
    faqs: FaqsSlice,
    video: VideoSlice,
    single_image: SingleImageSlice,
    rearrangeable_block: RearrangeableBlockSlice,
    event: EventSlice,
    cards_slider: CardsSliderSlice,
    rates: RatesSlice,
    location_map: LocationMapSlice,
    fullwidth_carousel: FullwidthCarouselSlice,
    text_in_columns: ColumnTextSlice,
    embedded_content: EmbeddedContentSlice,
    call_to_action: CallToActionSlice,
    stories: StoriesSlice,
  };

  return nodeBodyComponents;
}
