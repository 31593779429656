// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import SliceInnerContainer from '~components/SliceInnerContainer';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import TitleDivider from '~components/TitleDivider';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import getLinkedStoreType from '~helpers/getLinkedStoreType';

import type { Props } from './types';
import styles from './styles';
import messages from './messages';
import LocationMap from './LocationMap';

const LocationMapSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const storeInfo = getLinkedStoreType(data?.primary?.store_link);

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Container>
        <TitleDivider
          title={data.primary?.slice_title}
          description={data.primary?.slice_description}
          classes={{ root: classes.titleWrapper }}
        />
      </Container>
      <SliceInnerContainer data={data}>
        <Grid
          container
          alignItems="center"
          className={classes.itemsGridContainer}
        >
          <Grid
            item
            xs={12}
            {...(!!data?.primary?.show_additional_info ? { md: 6 } : undefined)}
            className={classes.itemsGridItemPrimary}
          >
            <LocationMap
              mapTitle={storeInfo?.data?.name?.text}
              src={storeInfo?.data?.store_map_link?.url}
            />
          </Grid>
          {data?.primary?.show_additional_info ? (
            <Grid
              item
              xs={12}
              md={6}
              className={classes.itemsGridItemSecondary}
            >
              <div className={classes.storeLocation}>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage {...messages.location} />
                </Typography>
                <RichTextTypography
                  variant="h6"
                  {...storeInfo?.data?.store_location}
                />
              </div>
              <div className={classes.storeHours}>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage {...messages.hours} />
                </Typography>
                <RichTextTypography
                  variant="h6"
                  {...storeInfo?.data?.store_hours}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
      </SliceInnerContainer>
    </Component>
  );
};

LocationMapSlice.defaultProps = {
  className: undefined,
};

export const StyledLocationMapSlice = withStyles<*, *, Props>(styles)(
  LocationMapSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledLocationMapSlice>,
>({ Container: null })(StyledLocationMapSlice);
