// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemsGridItem: {},
  itemTitle: {
    '& > *': {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  },
  itemText: {
    '& *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    '& a, & strong': {
      color: theme.palette.secondary.main,
    },
  },
});

export default styles;
