// @flow
import * as React from 'react';

export type Props = {
  separator: React.Node,
  children: React.Node,
};

export default function Join({ children, separator }: Props): React.Node {
  return (
    <>
      {React.Children.toArray(children)
        .filter(Boolean)
        .map((child: React.Node, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {index === 0 ? null : separator}
            {child}
          </React.Fragment>
        ))}
    </>
  );
}
