// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import type { PrismicStructuredTextType } from '~schema';

export type ClassKey = 'root' | 'title' | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title?: ?PrismicStructuredTextType,
  description?: ?PrismicStructuredTextType,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const textDividerGap = '1.5rem';

export const styles: Styles = theme => ({
  root: {
    textAlign: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '--text-divider-gap': textDividerGap,
    color: theme.palette.text.secondary,
    '& > *': {
      fontFamily: theme.typography.h1.fontFamily,
    },
    '&:before, &:after': {
      content: JSON.stringify(''),
      height: 1,
      maxWidth: '60%',
      backgroundColor: theme.palette.grey[300],
      flexGrow: 1,
    },
    '&:before': {
      marginRight: textDividerGap,
    },
    '&:after': {
      marginLeft: textDividerGap,
    },
  },
  description: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '60%',
      margin: 'auto',
    },
  },
});

const TitleDivider = ({
  title,
  description,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return hasRichTextFieldValue(title) ? (
    <div className={classnames(classes.root, className)} {...props}>
      <RichText {...title} className={classes.title} />
      <RichText {...description} className={classes.description} />
    </div>
  ) : null;
};

TitleDivider.defaultProps = {
  className: undefined,
  title: undefined,
  description: undefined,
};

export default withStyles<*, *, Props>(styles)(TitleDivider);
