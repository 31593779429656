// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { useKeenSlider } from 'keen-slider/react';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';
import CardsSliderSliceItem from './CardsSliderSliceItem';

const sliderMediaQuery = themeMediaQuery => themeMediaQuery.split('@media ')[1];

const CardsSliderSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const Component = component || Container;
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slidesPerView: 2,
    spacing: 20,
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    breakpoints: {
      [sliderMediaQuery(theme.breakpoints.down('xs'))]: {
        slidesPerView: 1,
      },
    },
  });

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <div key={index} className="keen-slider__slide">
              <CardsSliderSliceItem item={item} />
            </div>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid
        container
        spacing={6}
        alignItems="center"
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} md={6} className={classes.itemsGridItemPrimary}>
          {data.primary?.slice_title ? (
            <RichText
              {...data.primary?.slice_title}
              className={classes.title}
            />
          ) : null}
          {data.primary?.slice_description ? (
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          ref={sliderRef}
          className={classnames('keen-slider', classes.itemsGridItemSecondary)}
        >
          <>{renderedItems}</>
          {slider && (
            <div className={classes.navigation}>
              {[...Array(slider.details().size).keys()].map(index => {
                return (
                  <IconButton
                    key={index}
                    onClick={() => {
                      slider.moveToSlideRelative(index);
                    }}
                    aria-label={`Navigation dot ${index}`}
                    className={
                      classes.navigationDot +
                      (currentSlide === index ? ' active' : '')
                    }
                  />
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
    </Component>
  );
};

CardsSliderSlice.defaultProps = {
  className: undefined,
};

export const StyledCardsSliderSlice = withStyles<*, *, Props>(styles)(
  CardsSliderSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledCardsSliderSlice>,
>()(StyledCardsSliderSlice);
