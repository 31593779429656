// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import sanitizeButtonColor from '~plugins/material-ui/hocs/withBodySectionSlice/helpers/sanitizeButtonColor';

import type { Props } from './types';
import styles from './styles';

const RearrangeableBlockSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  return (
    <Component
      className={classnames(classes.root, className)}
      {...props}
      {...(data?.primary?.slice_width === 'Fullwidth'
        ? { ContainerProps: { maxWidth: false } }
        : null)}
    >
      <Grid
        container
        alignItems="center"
        spacing={6}
        className={classes.itemsGridContainer}
      >
        <Grid item xs={12} sm={6} className={classes.itemPrimaryWrapper}>
          {data.primary?.slice_title ? (
            <RichText
              {...data.primary?.slice_title}
              className={classes.title}
            />
          ) : null}
          {data.primary?.slice_description ? (
            <RichText
              {...data.primary?.slice_description}
              className={classes.description}
            />
          ) : null}
          {data?.primary?.slice_link ? (
            <Button
              component={FieldLink}
              field={data.primary?.slice_link}
              disableTouchRipple
              variant="contained"
              color={
                sanitizeButtonColor(data?.primary?.slice_background_color) ||
                'secondary'
              }
              aria-label={data?.primary?.slice_link_label?.text}
              className={classes.button}
            >
              {data?.primary?.slice_link_label?.text}
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.itemSecondaryWrapper}>
          <NodeImage
            data={data.primary?.slice_image}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

RearrangeableBlockSlice.defaultProps = {
  className: undefined,
};

export const StyledRearrangeableBlockSlice = withStyles<*, *, Props>(styles)(
  RearrangeableBlockSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledRearrangeableBlockSlice>,
>()(StyledRearrangeableBlockSlice);
