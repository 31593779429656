// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const ColumnTextSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid container spacing={6} className={classes.itemsGridContainer}>
        {map(data?.items, (item, index: number) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            className={classes.itemsGridItem}
          >
            <RichText {...item?.item_title} className={classes.itemTitle} />
            <RichText {...item?.item_text} className={classes.itemText} />
          </Grid>
        ))}
      </Grid>
    </Component>
  );
};

ColumnTextSlice.defaultProps = {
  className: undefined,
};

export const StyledColumnTextSlice = withStyles<*, *, Props>(styles)(
  ColumnTextSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledColumnTextSlice>,
>()(StyledColumnTextSlice);
