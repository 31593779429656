/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

export type ClassKey = 'root' | 'map';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  mapTitle: ?string,
  src: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {
    width: '100%',
    height: 400,
  },
  map: {
    width: '100%',
    height: '100%',
    border: 0,
  },
});

const LocationMap = ({
  src,
  mapTitle,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <iframe
        title={mapTitle}
        loading="lazy"
        className={classes.map}
        src={src}
      />
    </div>
  );
};

LocationMap.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(LocationMap);
