// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import type { PrismicStructuredTextType, PrismicImageType } from '~schema';

const getStoriesTextColor = color =>
  color !== null ? { color: color } : { color: '#fff' };

const getTextVerticalPosition = verticalPosition =>
  ({
    Top: {
      justifyContent: 'flex-start',
    },
    Middle: {
      justifyContent: 'center',
    },
    Bottom: { justifyContent: 'flex-end' },
  }[verticalPosition || 'Top']);

const getTextHorizontalPosition = horizontalPosition =>
  ({
    Left: {
      textAlign: 'initial',
    },
    Center: {
      textAlign: 'center',
    },
    Right: { textAlign: 'end' },
  }[horizontalPosition || 'Left']);

export type ClassKey =
  | 'root'
  | 'backgroundImage'
  | 'content'
  | 'title'
  | 'description';

export type Props = {
  // $FlowFixMe --> Reason: all branches are incompatible: Either cannot instantiate `WithStyles` because  string literal `root` [1] is incompatible with  `StyleRules` [2] in type argument `Rules`.
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title?: ?PrismicStructuredTextType,
  description?: ?PrismicStructuredTextType,
  backgroundImage?: ?PrismicImageType,
  color?: ?string,
  verticalPosition?: ?string,
  horizontalPosition?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    top: 0,
    left: 0,
    '&:after': {
      content: JSON.stringify(''),
      position: 'inherit',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.45)',
    },
  },
  content: ({ color, verticalPosition, horizontalPosition }) => ({
    position: 'inherit',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...getStoriesTextColor(color),
    ...getTextVerticalPosition(verticalPosition),
    ...getTextHorizontalPosition(horizontalPosition),
  }),
  title: {
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(2),
      textShadow: '4px 4px 15px rgba(0,0,0,.3)',
    },
  },
});

const StoriesSliceItem = ({
  title,
  description,
  backgroundImage,
  color,
  verticalPosition,
  horizontalPosition,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <NodeImage data={backgroundImage} className={classes.backgroundImage} />
      <div className={classes.content}>
        <RichText {...title} className={classes.title} />
        <RichText {...description} className={classes.description} />
      </div>
    </div>
  );
};

StoriesSliceItem.defaultProps = {
  className: undefined,
  title: undefined,
  description: undefined,
  backgroundImage: undefined,
  color: undefined,
  verticalPosition: undefined,
  horizontalPosition: undefined,
};

export default withStyles<*, *, Props>(styles)(StoriesSliceItem);
