// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { Parallax } from 'react-parallax';

import RichText from '~plugins/prismic/components/RichText';
import FieldLink, {
  hasLinkFieldValue,
} from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import SliceInnerContainer from '~components/SliceInnerContainer';

import type { Props } from './types';
import styles from './styles';

const ParallaxBannerSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceInnerContainer data={data}>
        <Parallax
          bgImage={data?.primary?.slice_background_image?.url}
          strength={300}
          className={classes.parallaxBackground}
        >
          <Container>
            <div className={classes.wrapper}>
              {data?.primary?.slice_title ? (
                <RichText
                  {...data?.primary?.slice_title}
                  classname={classes.title}
                />
              ) : null}
              {data?.primary?.slice_description ? (
                <RichText
                  {...data?.primary?.slice_description}
                  classname={classes.description}
                />
              ) : null}
              {hasLinkFieldValue(data?.primary?.slice_link) ? (
                <div className={classes.linkWrapper}>
                  <Button
                    component={FieldLink}
                    field={data.primary?.slice_link}
                    disableTouchRipple
                    variant="contained"
                    aria-label={data?.primary?.slice_link_label?.text}
                    className={classes.link}
                  >
                    {data?.primary?.slice_link_label?.text}
                  </Button>
                </div>
              ) : null}
            </div>
          </Container>
        </Parallax>
      </SliceInnerContainer>
    </Component>
  );
};

ParallaxBannerSlice.defaultProps = {
  className: undefined,
};

export const StyledParallaxBannerSlice = withStyles<*, *, Props>(styles)(
  ParallaxBannerSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledParallaxBannerSlice>,
>({ Container: null })(StyledParallaxBannerSlice);
