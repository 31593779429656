// @flow
import type { Styles } from './types';

const getTextAlignment = ({ data }) => {
  const size = data.primary?.slice_text_alignment;
  switch (size) {
    case 'Right':
      return {
        marginLeft: 'auto',
        textAlign: 'end',
      };
    case 'Center':
      return {
        margin: 'auto',
        textAlign: 'center',
      };
    default:
      return {
        textAlign: 'inherit',
      };
  }
};

const styles: Styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: ({ data }) =>
      data?.primary?.slice_width === 'Fullwidth' ? 0 : undefined,
    position: 'relative',
    color: theme.palette.common.white,
    '& .MuiContainer-root': {
      height: '100%',
      minHeight: '40vh',
    },
    '& .react-parallax:after': {
      content: JSON.stringify(''),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.15)',
      pointerEvents: 'none',
    },
  },
  parallaxBackground: {
    width: '100%',
    height: '100%',
    '& .react-parallax-content': {
      height: '100%',
      zIndex: 1,
    },
  },
  wrapper: ({ data }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textShadow: '2px 2px 6px rgba(0,0,0,.3)',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    ...getTextAlignment({ data }),
  }),
  title: {},
  description: {
    paddingTop: theme.spacing(2),
  },
  linkWrapper: {
    marginTop: theme.spacing(4),
    textShadow: 'none',
  },
  link: {},
});

export default styles;
