// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';

import TitleAndDescription from '~components/TitleAndDescription';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';
import RatesSliceItem from './RatesSliceItem';

const sliderMediaQuery = themeMediaQuery => themeMediaQuery.split('@media ')[1];

const RatesSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const Component = component || Container;
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slidesPerView: 2,
    spacing: 20,
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    breakpoints: {
      [sliderMediaQuery(theme.breakpoints.down('xs'))]: {
        slidesPerView: 1,
      },
    },
  });

  const sliderRenderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <div key={index} className="keen-slider__slide">
              <RatesSliceItem item={item} />
            </div>
          ) : null,
        ),
      ),
    [data.items],
  );

  const listRenderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid key={index} item className={classes.itemsGridItem}>
              <RatesSliceItem
                item={item}
                classes={{
                  root: classes.listItem,
                  image: classes.listItemImage,
                  content: classes.listItemContent,
                }}
              />
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {data?.primary?.slice_display === 'Slider' ? (
        <Grid container spacing={4} className={classes.itemsGridContainer}>
          <Grid item xs={12} md={5} className={classes.itemsGridItemPrimary}>
            <TitleAndDescription
              title={data?.primary?.slice_title}
              description={data?.primary?.slice_description}
              backgroundColor={data?.primary?.slice_background_color}
              classes={{ root: classes.title }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            {...(sliderRenderedItems.length >= 2 ? { ref: sliderRef } : null)}
            className={classnames(
              'keen-slider',
              classes.itemsGridItemSecondary,
            )}
          >
            {sliderRenderedItems}
            {slider && sliderRenderedItems.length >= 2 &&(
            <div className={classes.navigation}>
              {[...Array(slider.details().size).keys()].map(index => {
                return (
                  <IconButton
                    key={index}
                    onClick={() => {
                      slider.moveToSlideRelative(index);
                    }}
                    aria-label={`Navigation dot ${index}`}
                    className={
                      classes.navigationDot +
                      (currentSlide === index ? ' active' : '')
                    }
                  />
                );
              })}
            </div>
          )}
          </Grid>
        </Grid>
      ) : (
        <>
          <TitleAndDescription
            title={data?.primary?.slice_title}
            description={data?.primary?.slice_description}
            backgroundColor={data?.primary?.slice_background_color}
            classes={{ root: classes.title }}
          />
          <Grid container spacing={4} className={classes.itemsGridContainer}>
            {listRenderedItems}
          </Grid>
        </>
      )}
    </Component>
  );
};

RatesSlice.defaultProps = {
  className: undefined,
};

export const StyledRatesSlice = withStyles<*, *, Props>(styles)(RatesSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledRatesSlice>,
>()(StyledRatesSlice);
