// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import VideoPlayer from '~components/VideoPlayer';
import MediaCaption from '~components/MediaCaption';
import SliceInnerContainer from '~components/SliceInnerContainer';

import type { Props } from './types';
import styles from './styles';

const VideoSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';

  return data?.primary?.slice_video && data?.primary?.slice_video?.url ? (
    <Component
      className={classnames(classes.root, className)}
      {...props}
      Container={SliceInnerContainer}
      ContainerProps={{ data }}
    >
      <div className={classes.videoWrapper}>
        <VideoPlayer
          controls
          playsinline
          width="100%"
          height="100%"
          url={data?.primary?.slice_video?.url}
          videoThumbnail={data?.primary?.slice_video_image_caption?.url}
          classes={{
            root: classes.video,
          }}
        />
      </div>
      <MediaCaption
        text={data?.primary?.slice_caption}
        classes={{ root: classes.mediaCaption }}
      />
    </Component>
  ) : null;
};

VideoSlice.defaultProps = {
  className: undefined,
};

export const StyledVideoSlice = withStyles<*, *, Props>(styles)(VideoSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledVideoSlice>,
>()(StyledVideoSlice);
