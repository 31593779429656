// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    position: 'relative',
    padding: 0,
    '& .keen-slider': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '75vh',
    },
    '&:hover $navigation': {
      display: 'flex',
      opacity: 1,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.complex,
      }),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  slider: {
    position: 'relative',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    '&:after': {
      content: JSON.stringify(''),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.10)',
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  contentLeft: {
    left: '5%',
  },
  contentCenter: {
    left: '50%',
    transform: 'translateX(-50%)',
  },
  contentRight: {
    right: '5%',
  },
  title: {
    textShadow: '2px 2px 6px rgba(0,0,0,.15)',
  },
  description: {
    '& > *': {
      textShadow: '2px 2px 6px rgba(0,0,0,.15)',
      marginTop: theme.spacing(2),
    },
  },
  link: {
    marginTop: theme.spacing(3),
  },
  navigation: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    display: 'none',
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(3)}px`,
    transform: 'translateY(-50%)',
    opacity: 0,
  },
  navigationArrow: {
    border: '1px solid',
    color: theme.palette.common.white,
    '& svg': {
      fontSize: '2.6rem',
    },
  },
  bottomNavigation: {
    position: 'absolute',
    left: '50%',
    bottom: theme.spacing(3),
    transform: 'translateX(-50%)',
    width: 'auto',
  },
  bottomNavigationDot: {
    width: 8,
    height: 8,
    border: '1px solid',
    borderRadius: '50%',
    borderColor: theme.palette.grey[200],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    '&.active': {
      background: theme.palette.grey[200],
    },
  },
});

export default styles;
