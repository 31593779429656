// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import TitleDivider from '~components/TitleDivider';

import type { Props } from './types';
import styles from './styles';

const HighlightsSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const itemsPerRow = data.primary?.items_per_row;
  const gridItemProps = {
    '1': { xs: 12 },
    '2': { xs: 12, sm: 6 },
    '3': { xs: 12, sm: 6, md: 4 },
    '4': { xs: 12, sm: 6, md: 3 },
  }[itemsPerRow || '3'];

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          return item ? (
            <Grid
              key={index}
              item
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              {item?.item_image ? (
                <NodeImage
                  data={item.item_image}
                  className={classes.itemImage}
                />
              ) : null}
              {hasRichTextFieldValue(item.item_title) ||
              hasRichTextFieldValue(item.item_description) ? (
                <div className={classes.itemTextWrapper}>
                  {hasRichTextFieldValue(item.item_title) ? (
                    <RichText
                      {...item.item_title}
                      className={classes.itemTitle}
                    />
                  ) : null}
                  {hasRichTextFieldValue(item.item_description) ? (
                    <RichText
                      {...item.item_description}
                      className={classes.itemDescription}
                    />
                  ) : null}
                </div>
              ) : null}
            </Grid>
          ) : null;
        }),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <TitleDivider
        title={data.primary?.slice_title}
        description={data.primary?.slice_description}
        classes={{ root: classes.titleWrapper }}
      />
      <Grid
        container
        spacing={6}
        alignItems="baseline"
        justify="center"
        className={classes.itemsGridContainer}
      >
        {renderedItems}
      </Grid>
    </Component>
  );
};

HighlightsSlice.defaultProps = {
  className: undefined,
};

export const StyledHighlightsSlice = withStyles<*, *, Props>(styles)(
  HighlightsSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledHighlightsSlice>,
>()(StyledHighlightsSlice);
