// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  titleWrapper: {
    paddingBottom: theme.spacing(7),
  },
  expansionPanel: {
    marginBottom: theme.spacing(2),
    borderRadius: 4,
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
  },
  question: {
    color: theme.palette.text.secondary,
    '& > *': {
      fontFamily: theme.typography.h1.fontFamily,
    },
  },
  answer: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
});

export default styles;
