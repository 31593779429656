// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'carousel';

export default {
  arrowLeft: ({
    id: `${prefix}.arrowLeft`,
    defaultMessage: 'Navigate before',
    description: 'Left arrow',
  }: MessageDescriptor),
  arrowRight: ({
    id: `${prefix}.arrowRight`,
    defaultMessage: 'Navigate next',
    description: 'Right arrow',
  }: MessageDescriptor),
};
