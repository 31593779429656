// @flow
import type { Styles } from './types';
import imageHorizontalPosition from './config/imageHorizontalPosition';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemPrimaryWrapper: ({ data }) => ({
    ...(data.primary?.slice_image_position === imageHorizontalPosition.Before
      ? {
          [theme.breakpoints.up('sm')]: {
            order: 2,
          },
        }
      : undefined),
  }),
  itemSecondaryWrapper: ({ data }) => ({
    ...(data.primary?.slice_image_position === imageHorizontalPosition.Before
      ? {
          order: 1,
        }
      : undefined),
  }),
  title: {
    '& > *': {
      marginBottom: theme.spacing(2),
      color: ({ data }) =>
        data?.primary?.slice_background_color === null
          ? theme.palette.text.secondary
          : 'inherit',
    },
  },
  description: {
    '& *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    '& a, & strong': {
      color: theme.palette.secondary.main,
    },
  },
  button: { marginTop: theme.spacing(3) },
  image: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 300,
    },
  },
});

export default styles;
