// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import TitleAndDescription from '~components/TitleAndDescription';
import EmbeddedHtml from '~plugins/prismic/components/EmbeddedHtml';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const EmbeddedContentSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {data?.primary?.slice_title || data?.primary?.slice_text ? (
        <TitleAndDescription
          title={data?.primary?.slice_title}
          description={data?.primary?.slice_text}
          classes={{ root: classes.titleWrapper, title: classes.title }}
        />
      ) : null}
      <EmbeddedHtml
        {...(data?.primary?.slice_embedded_content || undefined)}
        className={classes.html}
      />
    </Component>
  );
};

EmbeddedContentSlice.defaultProps = {
  className: undefined,
};

export const StyledEmbeddedContentSlice = withStyles<*, *, Props>(styles)(
  EmbeddedContentSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledEmbeddedContentSlice>,
>()(StyledEmbeddedContentSlice);
