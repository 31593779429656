// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  title: ({ data }) => ({
    ...(data?.primary?.slice_display === 'Slider'
      ? {
          width: '100%',
        }
      : {
          ...theme.breakpoints.keys.reduce(
            (acc, breakpoint) => ({
              ...acc,
              [theme.breakpoints.up(breakpoint)]: {
                paddingBottom: theme.spacing(
                  getBreakPointFactor(breakpoint) * 2,
                ),
              },
            }),
            {},
          ),
        }),
  }),
  itemsGridContainer: {
    '& .keen-slider': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      clipPath: 'inset(-100vw -100vw -100vw 0)',
    },
  },
  itemsGridItem: {},
  ...{
    [theme.breakpoints.up('sm')]: {
      listItem: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      listItemImage: {
        width: '50%',
        borderBottomLeftRadius: 'inherit',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      listItemContent: {
        width: '50%',
        padding: theme.spacing(5),
      },
    },
  },
  itemsGridItemPrimary: {},
  itemsGridItemSecondary: {},
  navigation: {
    position: 'absolute',
    left: '50%',
    bottom: theme.spacing(-2),
    transform: 'translateX(-50%)',
    width: 'auto',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      display: ({ data }) => (data?.items.length <= 2 ? 'none' : undefined),
    },
  },
  navigationDot: {
    width: 8,
    height: 8,
    border: '1px solid',
    borderRadius: '50%',
    borderColor: ({ data }) =>
      data?.primary?.slice_background_color != null
        ? theme.palette.grey[200]
        : theme.palette.grey[600],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    '&.active': {
      background: ({ data }) =>
        data?.primary?.slice_background_color != null
          ? theme.palette.grey[200]
          : theme.palette.grey[600],
    },
  },
});

export default styles;
