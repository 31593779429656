// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  titleWrapper: {
    paddingBottom: theme.spacing(7),
  },
  itemsGridContainer: {},
  itemsGridItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  itemImage: {
    width: '100%',
    height: '100%',
    maxWidth: 100,
    '& img': {
      objectFit: 'contain !important',
    },
  },
  itemTextWrapper: {
    marginTop: theme.spacing(3),
  },
  itemTitle: {
    '& > *': {
      fontFamily: theme.typography.h1.fontFamily,
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  itemDescription: {},
});

export default styles;
