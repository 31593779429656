// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import MediaCaption from '~components/MediaCaption';
import SliceInnerContainer from '~components/SliceInnerContainer';

import type { Props } from './types';
import styles from './styles';

const SingleImageSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || "div";

  return (
    <Component
      className={classnames(classes.root, className)}
      {...props}
      Container={SliceInnerContainer}
      ContainerProps={{ data }}
    >
      <NodeImage data={data?.primary?.slice_image} className={classes.image} />
      <MediaCaption
        text={data?.primary?.slice_caption}
        classes={{ root: classes.mediaCaption }}
      />
    </Component>
  );
};

SingleImageSlice.defaultProps = {
  className: undefined,
};

export const StyledSingleImageSlice = withStyles<*, *, Props>(styles)(
  SingleImageSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledSingleImageSlice>,
>()(StyledSingleImageSlice);
