// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemsGridItemPrimary: {},
  itemsGridItemSecondary: {},
  headline: {
    marginBottom: theme.spacing(2),
  },
  quote: {
    '& *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  image: {
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
      maxHeight: 200,
      margin: 'auto',
    },
  },
});

export default styles;
