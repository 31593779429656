// @flow
import type { Styles } from './types';
import { hasRichTextFieldValue } from '~plugins/prismic/components/RichText';

const styles: Styles = theme => ({
  root: ({ data }) => ({
    ...(!hasRichTextFieldValue(data.primary?.slice_title)
      ? { padding: 0 }
      : undefined),
  }),
  titleWrapper: {
    paddingBottom: theme.spacing(7),
  },
  itemsGridContainer: {},
  itemsGridItemPrimary: {
    minHeight: 400,
  },
  itemsGridItemSecondary: {
    padding: theme.spacing(4),
  },
  storeLocation: {
    '& h6:first-child': {
      fontFamily: theme.typography.h1.fontFamily,
      color: ({ data }) =>
        data?.primary?.slice_background_color === null
          ? theme.palette.text.secondary
          : 'inherit',
    },
  },
  storeHours: {
    marginTop: theme.spacing(3),
    '& h6:first-child': {
      fontFamily: theme.typography.h1.fontFamily,
      color: ({ data }) =>
        data?.primary?.slice_background_color === null
          ? theme.palette.text.secondary
          : 'inherit',
    },
  },
});

export default styles;
