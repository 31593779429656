// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import compact from 'lodash/compact';
import map from 'lodash/map';
import Stories from 'react-insta-stories';

import TitleAndDescription from '~components/TitleAndDescription';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';
import StoriesSliceItem from './StoriesSliceItem';

const StoriesSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = React.useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setOpen(true);
    },
    [],
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const stories = React.useMemo(
    () =>
      compact(
        map(data?.items, (item, index: number) => {
          return item
            ? {
                // eslint-disable-next-line react/display-name
                content: () => {
                  return (
                    <StoriesSliceItem
                      key={index}
                      title={item?.item_title}
                      description={item?.item_description}
                      backgroundImage={item?.item_background_image}
                      color={item?.item_text_color}
                      verticalPosition={item?.item_text_vertical_position}
                      horizontalPosition={item?.item_text_horizontal_position}
                    />
                  );
                },
              }
            : null;
        }),
      ),
    [data?.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <TitleAndDescription
        title={data?.primary?.slice_title}
        description={data?.primary?.slice_description}
        classes={{ root: classes.titleWrapper, title: classes.title }}
      />
      <Button
        disableTouchRipple
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
        aria-label={data?.primary?.slice_link_label?.text}
        className={classes.button}
      >
        {data?.primary?.slice_link_label?.text}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <React.Suspense fallback={<h1>Loading…</h1>}>
          <Stories stories={stories} defaultInterval={5000} width="100%" />
        </React.Suspense>
      </Dialog>
    </Component>
  );
};

StoriesSlice.defaultProps = {
  className: undefined,
};

export const StyledStoriesSlice = withStyles<*, *, Props>(styles)(StoriesSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledStoriesSlice>,
>()(StyledStoriesSlice);
