// @flow
import type { PrismicLinkType, PrismicStore } from '~schema';

export default function getLinkedStoreType(
  link: ?PrismicLinkType,
): PrismicStore | void {
  const document = link && link.document;
  return document &&
    (document.__typename === 'PrismicStore' || document.type === 'store')
    ? // $FlowFixMe --> Reason: not infering type from `document.type`
      document
    : undefined;
}
