// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage, {
  hasNodeImageValue,
} from '~plugins/prismic/components/NodeImage';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import type { Props } from './types';
import styles from './styles';

const QuoteSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid container spacing={6} className={classes.itemsGridContainer}>
        {hasNodeImageValue(data?.primary?.slice_image) ? (
          <Grid item xs={12} sm={3} className={classes.itemsGridItemPrimary}>
            <NodeImage
              data={data?.primary?.slice_image}
              className={classes.image}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={9} className={classes.itemsGridItemSecondary}>
          {data?.primary?.slice_headline?.text ? (
            <Typography
              variant="overline"
              component="div"
              className={classes.headline}
            >
              {data?.primary?.slice_headline?.text}
            </Typography>
          ) : null}
          <RichText {...data?.primary?.slice_quote} className={classes.quote} />
        </Grid>
      </Grid>
    </Component>
  );
};

QuoteSlice.defaultProps = {
  className: undefined,
};

export const StyledQuoteSlice = withStyles<*, *, Props>(styles)(QuoteSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledQuoteSlice>,
>()(StyledQuoteSlice);
