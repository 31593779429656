// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  titleWrapper: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingBottom: theme.spacing(getBreakPointFactor(breakpoint) * 1.25),
        },
      }),
      {},
    ),
  },
  title: {
    color: theme.palette.text.secondary,
  },
  html: {},
});

export default styles;
