// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText from '~plugins/prismic/components/RichText';
import type { PrismicStructuredTextType, PrismicImageType } from '~schema';

export type ClassKey =
  | 'root'
  | 'image'
  | 'content'
  | 'contentTitle'
  | 'title'
  | 'subtitle'
  | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  item: {
    item_title?: ?PrismicStructuredTextType,
    item_subtitle?: ?PrismicStructuredTextType,
    item_description?: ?PrismicStructuredTextType,
    item_image?: ?PrismicImageType,
  },
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 300,
    position: 'relative',
    overflow: 'hidden',
    '&:hover $image': {
      borderRadius: '50%',
      transform: 'scale(0.45) translate(0px, 10%)',
    },
    '&:hover $content': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  image: {
    height: '100%',
    borderRadius: 'inherit',
    transformOrigin: 'top',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    transform: 'translateY(100%)',
    opacity: 0,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  contentTitle: {
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.secondary,
  },
  subtitle: {
    marginTop: theme.spacing(-0.5),
    '& > *': {
      ...theme.typography.caption,
      color: theme.palette.grey[600],
    },
  },
  description: {},
});

const CardsSliderSliceItem = ({
  item,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return item ? (
    <Paper className={classnames(classes.root, className)} {...props}>
      {item.item_image ? (
        <NodeImage data={item.item_image} className={classes.image} />
      ) : null}
      <div className={classes.content}>
        {item.item_title || item.item_subtitle ? (
          <div className={classes.contentTitle}>
            <RichText {...item.item_title} className={classes.title} />
            <RichText {...item.item_subtitle} className={classes.subtitle} />
          </div>
        ) : null}
        {item.item_description ? (
          <RichText
            {...item.item_description}
            className={classes.description}
          />
        ) : null}
      </div>
    </Paper>
  ) : null;
};

CardsSliderSliceItem.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(CardsSliderSliceItem);
