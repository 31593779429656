// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const CallToActionSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return data?.primary?.slice_link ? (
    <Component className={classnames(classes.root, className)} {...props}>
      {data.primary?.slice_title ? (
        <RichText {...data.primary?.slice_title} className={classes.title} />
      ) : null}
      <Button
        component={FieldLink}
        field={data.primary?.slice_link}
        disableTouchRipple
        variant="contained"
        color="secondary"
        aria-label={data?.primary?.slice_link_label?.text}
        className={classes.button}
      >
        {data?.primary?.slice_link_label?.text}
      </Button>
    </Component>
  ) : null;
};

CallToActionSlice.defaultProps = {
  className: undefined,
};

export const StyledCallToActionSlice = withStyles<*, *, Props>(styles)(
  CallToActionSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledCallToActionSlice>,
>()(StyledCallToActionSlice);
