// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: ({ data }) => ({
    ...(data?.primary?.slice_width === 'Fullwidth'
      ? {
          padding: 0,
        }
      : undefined),
  }),
  mediaCaption: {
    marginTop: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export default styles;
