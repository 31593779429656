// @flow
import type { ButtonProps } from '@material-ui/core/Button';

import * as sliceBackgroundColor from '../config/sliceBackgroundColor';

type ButtonColor = $ElementType<ButtonProps<*, {}>, 'color'>;

const buttonColorDict = ({
  [sliceBackgroundColor.Primary]: 'secondary',
  [sliceBackgroundColor.PrimaryLight]: 'secondary',
  [sliceBackgroundColor.PrimaryDark]: 'secondary',
  [sliceBackgroundColor.Secondary]: 'primary',
  [sliceBackgroundColor.SecondaryLight]: 'primary',
  [sliceBackgroundColor.SecondaryDark]: 'primary',
  [sliceBackgroundColor.Tertiary]: 'secondary',
  [sliceBackgroundColor.TertiaryLight]: 'secondary',
  [sliceBackgroundColor.TertiaryDark]: 'secondary',
  [sliceBackgroundColor.Quaternary]: 'secondary',
  [sliceBackgroundColor.QuaternaryLight]: 'secondary',
  [sliceBackgroundColor.QuaternaryDark]: 'secondary',
  [sliceBackgroundColor.Grey]: 'secondary',
  [sliceBackgroundColor.White]: 'secondary',
  [sliceBackgroundColor.Black]: 'secondary',
}: $ObjMap<typeof sliceBackgroundColor, <V>() => ButtonColor>);

const sanitizeButtonColor = (value: mixed): void | ButtonColor =>
  (!!value && typeof value === 'string' && buttonColorDict[value]) || undefined;

export default sanitizeButtonColor;
