// @flow
import type { Styles } from './types';

const getTextAlignment = ({ data }) => {
  const alignment = data.primary?.slice_text_alignment;
  switch (alignment) {
    case 'Right':
      return {
        marginLeft: 'auto',
        textAlign: 'end',
      };
    case 'Center':
      return {
        margin: 'auto',
        textAlign: 'center',
      };
    default:
      return {
        textAlign: 'inherit',
      };
  }
};

const styles: Styles = theme => ({
  root: ({ data }) => ({
    ...getTextAlignment({ data }),
  }),
  titleWrapper: ({ data }) => ({
    '& > *': {
      marginBottom: theme.spacing(3),
    },
    ...getTextAlignment({ data }),
  }),
  title: {
    '& > *': {
      color: theme.palette.text.secondary,
    },
  },
  button: {},
});

export default styles;
