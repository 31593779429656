// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    textAlign: 'center',
  },
  title: {
    '& > *': {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  },
  button: {},
});

export default styles;
