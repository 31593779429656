// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { useKeenSlider } from 'keen-slider/react';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText from '~plugins/prismic/components/RichText';
import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';
import messages from './messages';

const FullwidthCarouselSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slidesPerView: 1,
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          const contentAligment = item?.item_text_alignment;
          return item ? (
            <div key={index} className="keen-slider__slide">
              {item.item_background_image ? (
                <NodeImage
                  data={item.item_background_image}
                  className={classes.backgroundImage}
                />
              ) : null}
              <Container
                className={classnames(classes.content, {
                  [classes.contentLeft]: contentAligment === 'Left',
                  [classes.contentCenter]: contentAligment === 'Center',
                  [classes.contentRight]: contentAligment === 'Right',
                })}
              >
                {item?.item_title ? (
                  <RichText {...item?.item_title} className={classes.title} />
                ) : null}
                {item?.item_description ? (
                  <RichText
                    {...item?.item_description}
                    className={classes.description}
                  />
                ) : null}
                {item?.item_link ? (
                  <Button
                    component={FieldLink}
                    field={item?.item_link}
                    disableTouchRipple
                    variant="contained"
                    aria-label={item?.item_link_label?.text}
                    className={classes.link}
                  >
                    {item?.item_link_label?.text}
                  </Button>
                ) : null}
              </Container>
            </div>
          ) : null;
        }),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <div
        ref={sliderRef}
        className={classnames('keen-slider', classes.slider)}
      >
        {renderedItems}
      </div>
      {slider && (
        <>
          <div className={classes.navigation}>
            <IconButton
              aria-label={messages.arrowLeft}
              className={classes.navigationArrow}
              onClick={e => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
              size="small"
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              aria-label={messages.arrowRight}
              className={classes.navigationArrow}
              onClick={e => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
              size="small"
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
          <div className={classes.bottomNavigation}>
            {[...Array(slider.details().size).keys()].map(index => {
              return (
                <IconButton
                  key={index}
                  onClick={() => {
                    slider.moveToSlideRelative(index);
                  }}
                  aria-label={`Navigation dot ${index}`}
                  className={
                    classes.bottomNavigationDot +
                    (currentSlide === index ? ' active' : '')
                  }
                />
              );
            })}
          </div>
        </>
      )}
    </Component>
  );
};

FullwidthCarouselSlice.defaultProps = {
  className: undefined,
};

export const StyledFullwidthCarouselSlice = withStyles<*, *, Props>(styles)(
  FullwidthCarouselSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledFullwidthCarouselSlice>,
>({ Container: null })(StyledFullwidthCarouselSlice);
