// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'event';

export default {
  meetTime: ({
    id: `${prefix}.meetTime`,
    defaultMessage: 'MEET TIME: ',
    description: 'Ride meet time',
  }: MessageDescriptor),
};
